import Image from '@/components/Image'
import React from 'react'

const About = () => {
  return (
    <section className="section-padding section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="image mb-xl-30">
              <iframe
                src="https://www.youtube.com/embed/PLdqCkqjBIw"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                style={{ width: '100%', height: '270px', borderRadius: '10px' }}
              ></iframe>
              {/* <Image name="AboutUs.webp" classes="image-fit" /> */}
              {/* <img src="images/about_two.png" alt="img" className="image-fit" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-header">
              {/* <p className="section_count"></p> */}
              <h3 className="title">About Us</h3>
            </div>
            <p>
              Jet Air MRO provides long-term, cost-effective maintenance, repair, and overhaul
              services for commercial aircraft components and accessories. We are experienced
              professionals committed to responsiveness within the shortest possible timeframe while
              maintaining the highest industry standards of quality, safety, and efficiency. Our
              solutions guarantee the total satisfaction of our clients at all times.
            </p>
            <div className="row">
              <div className="col-sm-6">
                <div className="icon_box">
                  <img src="images/reliability-icon.svg" />
                  <h5 className="title mb-0">
                    Reliable <br /> Services
                  </h5>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="icon_box">
                  <img src="images/optimization-icon.svg" />
                  <h5 className="title mb-0">
                    Process
                    <br /> Optimization
                  </h5>
                </div>
              </div>
            </div>
            <a href="/about" className="thm-btn btn-rounded mb-xl-30">
              <span className="button_title">Read More</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
